import React, {useEffect, useState} from 'react'

import Head from 'next/head'

import Layout from '../app/containers/layout/layout'
import Home from '../app/containers/home/home'

import StrapiAPI from '../app/data/strapi';

/**
 * equivalent to landing page
 */
const HomePage = () => {
    const [categories, setCategories] = useState(false)
    const [loading, setLoading] = useState(true)

    const _load = async () => {
        let categories = await StrapiAPI.getAllCategories()
        setCategories(categories.data)
    }

    useEffect(() => {
        _load().then(() => setLoading(false))
    }, [])

    return (
        <React.Fragment>
            <Head>
                <title>BGV | Home</title>
                <meta name='viewport' content='width=device-width, initial-scale=1.0, user-scalable=no'/>
            </Head>
            <Layout>

                {!loading && <Home categories={categories} />}
            </Layout>
        </React.Fragment>
    );
}

export default HomePage
